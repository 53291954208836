import bigcommerceSparkDefaults from '../bigcommerce-spark/config.js';

const { baseFields } = bigcommerceSparkDefaults.fitmentSearch;
const ignoreFields = bigcommerceSparkDefaults.Widgets.find((w) => w.name === 'FacetPanel')?.ignoreFields;
const categorySelectionPageUrl = '/categories/';

const wheelsFields = bigcommerceSparkDefaults.Widgets.find((w) => w.name === 'FacetBarWheels').fields;
const tiresFields = bigcommerceSparkDefaults.Widgets.find((w) => w.name === 'FacetBarTires').fields;

export default {
  includes: ['bigcommerce-spark'],
  ...bigcommerceSparkDefaults,
  defaultView: 'productGrid',
  fitmentSearch: {
    ...bigcommerceSparkDefaults.fitmentSearch,
    isVehicleSelectionIsolated: true,
  },
  Widgets: [
    ...bigcommerceSparkDefaults.Widgets.filter(
      (w) =>
        !['SearchPage', 'CategoryPage', 'SearchBox', 'FacetPanel', 'FacetDialog'].includes(w.name) &&
        !w.name.startsWith('HomeVehicleWidget'),
    ),
    {
      name: 'SearchPage',
      location: { selector: '#cm_results', class: 'animate-loaded animated' },
      template: 'SearchPage',
    },
    {
      name: 'FilterChips',
      type: 'FacetPanel',
      ignoreFields,
    },
    {
      name: 'Facets',
      type: 'FacetPanel',
      ignoreFields,
    },
    {
      name: 'CategoryPage',
      type: 'SearchPage',
      location: {
        replace: ['page-type-category', 'page-type-brand']
          .map((className) => `body.${className} .page`)
          .join(),
        class: 'animate-loaded animated',
      },
      template: 'SearchPage',
      visibleIf: () => window.location.pathname !== categorySelectionPageUrl,
    },
    {
      name: 'SearchBox',
      location: '#quickSearch',
      template: 'SearchBox',
    },
    {
      name: 'YMM_tab',
      type: 'RequestPanel',
      location: { selector: '#cm-home-ymm', class: 'cm_vehicle-widget__home-wheeltiretab' },
      template: 'HomeWheelTireBar',
      needInitRequest: true,
      selectFields: [...baseFields, 'tire_rim'],
      requestExtra: { rimExpand: true },
    },
    {
      name: 'WheelPanel_tab',
      type: 'RequestPanel',
      location: { selector: '#cm-home-wheel', class: 'cm_vehicle-widget__home-wheeltiretab' },
      template: 'HomeWheelTireBar',
      needInitRequest: true,
      redirectUrl: '/wheels/',
      selectFields: wheelsFields,
    },
    {
      name: 'TirePanel_tab',
      type: 'RequestPanel',
      location: { selector: '#cm-home-tire', class: 'cm_vehicle-widget__home-wheeltiretab' },
      template: 'HomeWheelTireBar',
      needInitRequest: true,
      redirectUrl: '/tires/',
      selectFields: tiresFields,
    },
    {
      name: 'FacetDialog',
      template: 'FacetDialog',
      ignoreFields: ['Universal', 'Vehicle'],
    },
  ],
};
